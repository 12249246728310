var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":""}},[_c('modal-title',{attrs:{"text":_vm.id ? 'EDIT CATEGORY' : 'ADD CATEGORY'}}),_c('v-card-text',{staticClass:"pt-4"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveCategory($event)}}},[_c('v-row',{staticClass:"justify-center align-center"},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-avatar',_vm._g({staticClass:"hoverPointer",attrs:{"color":_vm.color,"size":"75"}},on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-palette")])],1)]}}])},[_c('v-color-picker',{staticClass:"ma-2",attrs:{"hide-inputs":""},model:{value:(_vm.color),callback:function ($$v) {_vm.color=$$v},expression:"color"}})],1)],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Name","name":"name","textarea":"","error-messages":_vm.nameAvailable},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('v-col',{attrs:{"cols":"10","offset":"2"}},[_c('v-tooltip',{attrs:{"color":"primary","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-sheet',_vm._g({attrs:{"color":"transparent","max-width":"250"}},on),[_c('v-switch',{attrs:{"disabled":_vm.isDefaultCategory || !_vm.id,"messages":_vm.isDefaultCategory
                      ? 'Toggle default on another category to remove.'
                      : _vm.id
                      ? ("Set default category and remove from: " + (_vm.defaultCategory.name) + " ")
                      : 'Create new category to set as default',"label":"Default Category","value":_vm.isDefaultCategory},on:{"change":_vm.setDefaultCategory}})],1)]}}])},[_c('v-sheet',{staticClass:"secondary--text",attrs:{"color":"transparent"},domProps:{"innerHTML":_vm._s(
                _vm.isDefaultCategory
                  ? 'Toggle default on another category to remove.'
                  : ("Set as default category. <br /> (currently: " + (_vm.defaultCategory.name) + ")")
              )}})],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px","transition":"dialog-transition"},model:{value:(_vm.modalConfirmDelete),callback:function ($$v) {_vm.modalConfirmDelete=$$v},expression:"modalConfirmDelete"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',{staticClass:"justify-center title error--text"},[_vm._v(" CONFIRM DELETE ")]),_c('v-card-text',[_c('v-row',{staticClass:"justify-center align-center"},[_c('v-col',{staticClass:"align-center",attrs:{"cols":"12"}},[_c('p',{staticClass:"font-weight-bold text-center"},[_vm._v(" WARNING: You are about to delete the category called \""+_vm._s(_vm.name)+"\". ")]),_c('p',{staticClass:" text-center"},[_vm._v(" All catalog items under this category will be set to the configured default category of "),_c('strong',[_vm._v("\""+_vm._s(_vm.defaultCategory.name)+"\"")])])])],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-space-around"},[_c('v-btn',{attrs:{"color":"warning","text":""},on:{"click":function($event){_vm.modalConfirmDelete = !_vm.modalConfirmDelete}}},[_vm._v("CANCEL")]),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":_vm.deleteCategory}},[_vm._v("DELETE")])],1)],1)],1)],1),_c('v-card-actions',[_c('v-tooltip',{attrs:{"color":"primary","value":!_vm.id,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-sheet',_vm._g({attrs:{"color":"transparent"}},on),[_c('v-btn',{attrs:{"text":"","color":"error","disabled":!_vm.id || _vm.isDefaultCategory,"loading":_vm.loading === 'delete'},on:{"click":function($event){_vm.modalConfirmDelete = !_vm.modalConfirmDelete}}},[_vm._v("DELETE")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.isDefaultCategory ? 'Cannot delete default category.' : 'Delete category'))])]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-4",attrs:{"text":"","color":_vm.saveDisabled ? 'primary' : 'warning'},on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.saveDisabled ? 'CLOSE' : 'CANCEL'))]),_c('v-btn',{attrs:{"text":"","color":"success","disabled":_vm.saveDisabled,"loading":_vm.loading === 'save'},on:{"click":_vm.saveCategory}},[_vm._v("SAVE")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }